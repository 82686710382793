import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'

import './apply.css'

const Apply = () => {
  return (
    // <div className="apply-block-container">
    //   <h2 className="apply-heading">Apply for a Position</h2>
    //   <Form>
    //     {/* Name */}
    //     <Form.Group className="apply-block mb-3" controlId="formBasicName">
    //       <Form.Label>Name</Form.Label>
    //       <Form.Control type="name" placeholder="Enter your name" />
    //       <Form.Text className="text-muted"></Form.Text>
    //     </Form.Group>

    //     {/* Email */}
    //     <Form.Group className="apply-block mb-3" controlId="formBasicEmail">
    //       <Form.Label>Email address</Form.Label>
    //       <Form.Control type="email" placeholder="Enter email" />
    //       <Form.Text className="text-muted"></Form.Text>
    //     </Form.Group>

    //     {/* Program */}
    //     <Form.Group className="apply-block mb-3" controlId="formBasic">
    //       <Form.Label>Program</Form.Label>
    //       <Form.Control type="text" placeholder="What program are you in?" />
    //       <Form.Text className="text-muted"></Form.Text>
    //     </Form.Group>

    //     {/* Academic Year */}
    //     <Form.Group className="apply-block mb-3" controlId="formBasic">
    //       <Form.Label>Academic Year</Form.Label>
    //       <Form.Control type="text" placeholder="Which year are you in?" />
    //       <Form.Text className="text-muted"></Form.Text>
    //     </Form.Group>

    //     {/* Field(s) of interest */}
    //     <Form.Group className="apply-block mb-3" controlId="formBasicCheckbox">
    //       <Form.Label>Field(s) of Interest</Form.Label>
    //       <div className="apply-checklist">
    //         <Form.Check type="checkbox" label="Field" />
    //         <Form.Check type="checkbox" label="Field" />
    //         <Form.Check type="checkbox" label="Field" />
    //       </div>
    //     </Form.Group>
    //     <Button variant="dark" type="submit">
    //       Submit
    //     </Button>
    //   </Form>
    // </div>
    <div>
      <br></br>
      <br></br>
      <br></br>
      <center> <p> Please contact us about your prior experience and your interest. </p> </center>
      {/* <ul>
        <li>
          Team Captain:
        </li>
        <li>
          Software Engineering: Daniel Mao <a href="mailto:danielmao2019@gmail.com"> danielmao2019@gmail.com </a>
        </li>
      </ul> */}
    </div>
  )
}

export default Apply;
