import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import { Navbar, Nav, NavDropdown, Container, NavbarBrand } from 'react-bootstrap'

import './header.css'
import Logo from '../image/LOGO.png'

const Header = () => {
  return (
    <div>
      <Navbar bg="black" variant="dark" collapseOnSelect expand="lg">
        <Container className="nav-container">
          <Navbar.Brand href="/" >
            <img
              alt=""
              src={Logo}
              width="55"
              className="d-inline-block align-top"
            />
            <div className='nav-name'> UWMedTechResolve </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse aria-controls="responsive-navbar-nav">
            <Nav>
              <Nav.Link href="/"> Our Mission </Nav.Link>
              <Nav.Link href="team-org"> Team Org </Nav.Link>
              <Nav.Link href="apply"> Join Us </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header
