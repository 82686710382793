import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "./home.css";
import weef_logo from '../image/weef_logo.png'
import air_logo from '../image/AIRLAB_logo_small.png'
import autonomous_wheelchair_concept from '../image/autonomous_wheelchair_concept.png'


const Home = () => {
  return (
    <div>
      <div className="home-block-container">
        {/* Motivation */}
        <div className="home-block">
          <h1>Motivation</h1>
          <p>
            With existing technology, the Canadian healthcare industry has yet
            to reach its full potential in terms of efficiency and accuracy.
          </p>
          <p>
            The integration of robotics into the healthcare system in Canada
            has the potential to revolutionize the way we provide care to patients.
            Robots can assist medical professionals in performing tasks such as surgery,
            therapy, and diagnostic testing.
          </p>
          <p>
            The key to this integration is the Human-Robot Interaction (HRI) aspect,
            which enables the robots to work in collaboration with human healthcare professionals,
            in a way that is seamless, intuitive and safe for patients.
            This approach improves not only the efficiency and accuracy of these procedures,
            but also allows medical staff to focus on more critical tasks such as patient care and communication.
          </p>
        </div>

        {/* Objectives */}
        <div className="home-block">
          <h1>Objectives</h1>
          <p>
            UWMedTechResolve aims to improve the current state of medical technology
            to increase accessibility of medical treatment and long-term care.
          </p>
          <p>
            We participate in{" "}
            <b> Johns Hopkins Healthcare Design Competition </b>
            yearly.
          </p>
          <p>Join us and make a difference in the healthcare industry!</p>
          <p className="home-block-date">March 15, 2022</p>
        </div>

        {/* Current Project */}
        <div className="home-block">
          <h1>Current Project</h1>
          <Container>
            <Row>
              <Col>
                <img
                  alt=""
                  src={autonomous_wheelchair_concept}
                  className="concept-img"
                />
              </Col>
              <Col>
                <div className="home-list">
                  <h3>Current Project: Triage Automation</h3>
                  <ul>
                    <li>Vital Signs Monitoring </li>
                    <li>Symptoms Reporting </li>
                    <li>Autonomous Navigation</li>
                  </ul>
                  <p>
                    Equipped with vital sign detection device, this wheelchair can measure patient's heart rate, blood pressure, oxygen levels, and temperature with ease.
                    And with user-friendly interface, patient can input the symptoms with a touch screen or voice recognition system.
                    The communication system ensures that all the vital signs and symptoms data is sent directly to the doctor, who can then give commands for navigation and other tasks.
                    Our advanced navigation system allows the wheelchair to navigate in the hospital environment, avoiding obstacles and taking patient to the destination.
                    With remote monitoring system, the physician can keep an eye on the patient's health status from anywhere.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Partners & Sponsors */}
        <div className="home-block" id="partner-sponsor-block">
          <h1>Partners & Sponsors</h1>
          <Container>
            <Row>
              <Col>
                <div className="logo-container">
                  <img
                    alt=""
                    src={weef_logo}
                    className="logo-img"
                  />
                </div>
                <div>
                  <h5
                    onClick={() =>
                      window.open("https://uwaterloo.ca/engineering-endowment-foundation/")
                    }
                    className="contact-link"
                  >
                    {" "}
                    Waterloo Engineering Endowment Foundation{" "}
                  </h5>
                </div>
              </Col>
              <Col>
                <div className="logo-container">
                  <img
                    alt=""
                    src={air_logo}
                    className="logo-img"
                  />
                </div>
                <div>
                  <h5
                    onClick={() =>
                      window.open("https://uwaterloo.ca/active-and-interactive-robotics-lab/")
                    }
                    className="contact-link"
                  >
                    {" "}
                    Active and Interactive Robotics Lab{" "}
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
          <div id="partner-sponsor-container"></div>
        </div>

        {/* Contact Us */}
        <div className="home-block" id="contact-us-block">
          <h1>Contact Us</h1>
          <Container>
            <Row>
              <Col>
                <div className="contact-col">
                  <h5
                    onClick={() =>
                      window.open("mailto:uwmedtechresolve@outlook.com")
                    }
                    className="contact-link"
                  >
                    {" "}
                    Email{" "}
                  </h5>
                </div>
              </Col>
              <Col>
                <div className="contact-col">
                  <h5
                    onClick={() =>
                      window.open("https://www.instagram.com/uwmedtechresolve/")
                    }
                    className="contact-link"
                  >
                    {" "}
                    Instagram{" "}
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
          <div id="contact-us-container"></div>
        </div>
      </div>
    </div>
  );
};

export default Home;
