import React, { Component } from "react";
import "./team_org.css";

const TeamOrgData = [
  {
    heading: "Software Division",
    list: [
      {
        head: "Full-Stack Web Developer",
        body: [
          "Official site feature upgrades and maintenance",
          "Human resource management web app",
        ],
      },
      {
        head: "Machine Learning Engineer",
        body: [
          "Data input pipelines",
          "Deep learning modules",
          "Model evaluation, error analysis, and visualization tools",
          "Neural networks development",
        ],
      },
      {
        head: "Robotics Software Engineer",
        body: [
          "Mapping algorithm implementation",
          "Localization algorithm implementation",
          "Infrastructure development",
          "HRI perception research",
          "HRI decision making research"
        ],
      },
    ],
  },
  {
    heading: "Hardware Division",
    list: [
      {
        head: "Chassis Design Engineer",
        body: [
          "Chassis design for the autonomous wheelchair",
          "Vital sign monitoring devices integration",
        ],
      },
      {
        head: "Test Engineer",
        body: [
          "Feature testing",
          "Safety validation",
        ],
      },
    ],
  },
  {
    heading: "Business Division",
    list: [
      {
        head: "Accountant",
        body: [],
      },
      {
        head: "Designer",
        body: ["Posters, logo, web pages, team uniforms design"],
      },
      {
        head: "Public Relations manager",
        body: ["Team email, twitter, instagram, and WeChat accounts management"],
      },
    ],
  },
];

const TeamOrg = () => {
  return (
    <div className="team-org-block-container">
      {/* Team Org */}
      <div className="team-org-block">
        <h1> Team Org </h1>
        <div className="team-org-list"> {getNestedList(TeamOrgData)} </div>
      </div>
    </div>
  );
};

const getNestedList = (data) => {
  return data.map((list) => {
    return (
      <div>
        <h5>
          <b>{list.heading}</b>
        </h5>
        {list.list.map((sublist) => {
          return (
            <ul>
              <li> {sublist.head} </li>
              <ul>
                {sublist.body.map((subitem) => {
                  return <li>{subitem}</li>;
                })}
              </ul>
            </ul>
          );
        })}
      </div>
    );
  });
};

export default TeamOrg;
